@media print {
    /* hide all elements except for the table */
    body * {
      visibility: hidden;
    }
    #order-listing, #order-listing * {
      visibility: visible;
    }
    #order-listing {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  .btn{
     border: #000;
  }


  /* table.css */

.content-wrapper {
  /* margin: 20px; */
}

.card-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.table-responsive {
  overflow-x: auto;
}

.row {
  /* margin-bottom: 20px; */
}

.col-lg-4 {
  display: flex;
  align-items: center;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dropdown-toggle {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 12px;
  border-radius: 5px;
}

.dropdown-toggle:hover {
  background-color: #f8f9fa;
}

.dropdown-menu {
  min-width: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: none;
}

.dropdown-item {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.text-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  margin-right: 10px;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  margin-right: 5px;
}

.page-link {
  padding: 5px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.page-link:hover {
  background-color: #f8f9fa;
}

.page-item.disabled .page-link {
  pointer-events: none;
  background-color: #fff;
  border-color: #ccc;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.page-item.active .page-link:hover {
  background-color: #007bff;
}

/* Styling for export buttons */
.btn-primary,
.btn-success {
  padding: 5px 12px;
  font-size: 14px;
  border-radius: 5px;
}

.btn-primary:hover,
.btn-success:hover {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
